import { useModalStore } from "@/stores/modal-store";
interface ModalLayoutProps {
  children: React.ReactNode;
  onClose: () => void;
}
export default function ModalLayout({
  children,
  onClose
}: ModalLayoutProps) {
  const {
    modalConfig
  } = useModalStore();
  const layoutOptions = modalConfig?.layoutOptions;
  if (!layoutOptions) return <>{children}</>;
  return <div className={`flex flex-col relative ${layoutOptions.className || ""}`} data-sentry-component="ModalLayout" data-sentry-source-file="modal-layout.tsx">
      {layoutOptions.header && <div className="px-6 py-4 border-b">{layoutOptions.header}</div>}

      {layoutOptions.showCloseButton && <button onClick={onClose} className={`absolute p-2 hover:bg-gray-100 rounded-full transition-colors ${layoutOptions.closeButtonPosition === "top-left" ? "left-4" : "right-4"} top-4`}>
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>}

      <div className={`flex-1 ${layoutOptions.padding || ""}`}>{children}</div>

      {layoutOptions.footer && <div className="px-6 py-4 border-t">{layoutOptions.footer}</div>}
    </div>;
}