"use client";

import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";
import { ClubRead } from "@/TS/Interfaces/global_interfaces";
export interface ClubMapContextProps {
  clubMapValues?: ClubMapValues;
  setClubMapValues?: Dispatch<SetStateAction<ClubMapValues>>;
  isShown?: () => boolean;
  resetClubMapValues?: any;
}
export interface ClubMapValues {
  x?: number | null;
  y?: number | null;
  club?: ClubRead | null;
  isMarkerHover?: boolean;
  isPopinHover?: boolean;
}
export const clubMapContext = createContext<ClubMapContextProps | null>(null);
function ClubMapContextProvider({
  children
}: {
  children: ReactNode;
}): ReactNode {
  const initialState: ClubMapValues = {
    x: null,
    y: null,
    club: null,
    isMarkerHover: false,
    isPopinHover: false
  };
  const [clubMapValues, setClubMapValues] = useState<ClubMapValues>(initialState);
  function isShown(): boolean {
    if (!clubMapValues) return false;
    return Boolean(clubMapValues.isMarkerHover) || Boolean(clubMapValues.isPopinHover);
  }
  function resetClubMapValues(): void {
    setClubMapValues(initialState);
  }
  return <clubMapContext.Provider value={{
    clubMapValues,
    setClubMapValues,
    isShown,
    resetClubMapValues
  }} data-sentry-element="unknown" data-sentry-component="ClubMapContextProvider" data-sentry-source-file="club-map-context.tsx">
      {children}
    </clubMapContext.Provider>;
}
export default ClubMapContextProvider;