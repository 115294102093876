import { create } from "zustand";

import {
  ModalDefaultFields,
  ModalLayoutDefaultFields,
  ModalLayoutOptions,
  ModalOptions,
  ModalStore,
} from "./modal-store.types";

/**
 * Default options for modal and layout configuration
 * @constant
 * @type {{
 *   modal: Required<Pick<ModalOptions, ModalDefaultFields>>,
 *   layout: Required<Pick<ModalLayoutOptions, ModalLayoutDefaultFields>>
 * }}
 */
const DEFAULT_OPTIONS: {
  modal: Required<Pick<ModalOptions, ModalDefaultFields>>;
  layout: Required<Pick<ModalLayoutOptions, ModalLayoutDefaultFields>>;
} = {
  modal: {
    size: {
      width: "max-w-3xl",
      minHeight: "min-h-[30vh]",
    },
    closeOnOverlayClick: true,
    closeOnEscape: true,
    preventScroll: true,
    animation: {
      enter: "animate-fadeInCustom",
      leave: "animate-fadeOutCustom",
      enterDuration: 300,
      leaveDuration: 200,
    },
  },
  layout: {
    showCloseButton: true,
    closeButtonPosition: "top-right",
    padding: "p-6",
  },
};

/**
 * Zustand store for modal management
 * @function useModalStore
 * @returns {ModalStore} Modal store instance
 *
 * @property {boolean} isOpen - Current modal open state
 * @property {ModalConfig | null} modalConfig - Current modal configuration
 *
 * @method open
 * @param {ModalConfig} config - Configuration for opening a new modal
 * @param {React.ReactNode} config.component - React component to display in the modal
 * @param {ModalOptions} [config.modalOptions] - Modal configuration options
 * @param {Object} [config.modalOptions.size] - Modal dimensions
 * @param {string} [config.modalOptions.size.width] - Modal max width
 * @param {string} [config.modalOptions.size.height] - Modal height
 * @param {string} [config.modalOptions.size.minHeight] - Modal minimum height
 * @param {boolean} [config.modalOptions.closeOnOverlayClick] - Enable closing on overlay click
 * @param {boolean} [config.modalOptions.closeOnEscape] - Enable closing on Escape key
 * @param {boolean} [config.modalOptions.preventScroll] - Prevent page scrolling when modal is open
 * @param {Function} [config.modalOptions.onClose] - Callback triggered on modal close
 * @param {Function} [config.modalOptions.onOpen] - Callback triggered on modal open
 * @param {string} [config.modalOptions.className] - Additional CSS classes for modal
 * @param {string} [config.modalOptions.overlayClassName] - Additional CSS classes for overlay
 * @param {Object} [config.modalOptions.loader] - Loading state configuration
 * @param {ReactElement<ComponentLoadingProps>} [config.modalOptions.loader.component] - Custom loading component (must be ComponentLoading)
 * @param {ModalSkeletonOptions} [config.modalOptions.loader.skeleton] - Loading skeleton configuration
 * @param {ModalLayoutOptions} [config.layoutOptions] - Layout configuration options
 * @param {boolean} [config.modalOptions.animation.leaveDuration] - Duration of the leave animation
 * @param {boolean} [config.modalOptions.animation.enterDuration] - Duration of the enter animation
 *
 * @method handleClose
 * @description Closes the active modal and resets its configuration
 *
 * @method close
 * @description Closes the active modal and resets its configuration
 *
 * @example
 * // Opening a modal
 * useModalStore.getState().open({
 *   component: <MyComponent />,
 *   // Add modal custom options here
 *   modalOptions: {
 *     size: { width: "max-w-2xl" },
 *     closeOnOverlayClick: true,
 *     loader: {
 *       // Use a custom loading component
 *       component: <ComponentLoading />,
 *       // Or use a skeleton
 *       skeleton: {
 *         className: "bg-gray-100",
 *         content: {
 *           distribution: "equal",
 *           items: [
 *             { count: 1, height: "15%", variant: "rectangular" },
 *             { count: 2, height: "20%", gap: 4, variant: "rectangular" }
 *           ]
 *         }
 *       }
 *     }
 *   },
 *   // Add modal layout custom options here
 *   layoutOptions: {
 *     showCloseButton: true,
 *     closeButtonPosition: "top-right",
 *     padding: "p-6",
 *   },
 * });
 *
 * // Closing the modal
 * useModalStore.getState().close();
 */
export const useModalStore = create<ModalStore>((set, get) => ({
  isOpen: false,
  modalConfig: null,
  open: (config) => {
    const newConfig = {
      ...config,
      modalOptions: {
        ...DEFAULT_OPTIONS.modal,
        ...config.modalOptions,
        size: {
          ...DEFAULT_OPTIONS.modal.size,
          ...config.modalOptions?.size,
        },
        loader: config.modalOptions?.loader,
      },
      layoutOptions: {
        ...DEFAULT_OPTIONS.layout,
        ...config.layoutOptions,
      },
    };
    set({
      isOpen: true,
      modalConfig: newConfig,
    });
  },
  handleClose: () => {
    const { modalConfig } = get();
    if (!modalConfig?.modalOptions?.animation?.leaveDuration) {
      get().close();
      return;
    }

    const modalElement = document.querySelector("#modal-root");
    if (modalElement) {
      modalElement.classList.remove("animate-fadeInCustom");
      modalElement.classList.add("animate-fadeOutCustom");
    }

    setTimeout(() => {
      get().close();
    }, modalConfig.modalOptions.animation.leaveDuration);
  },
  close: () => {
    const currentConfig = get().modalConfig;
    currentConfig?.modalOptions?.onClose?.();
    set({ isOpen: false, modalConfig: null });
  },
}));
