"use client";

import { useEffect, type MouseEvent, useState } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";
import { useModalStore } from "@/stores/modal-store";
import ModalLayout from "./modal-layout";
export const Modal = () => {
  const {
    isOpen,
    modalConfig,
    handleClose
  } = useModalStore();
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape" && modalConfig?.modalOptions?.closeOnEscape) {
        handleClose();
      }
    };
    if (isOpen && modalConfig?.modalOptions?.closeOnEscape) {
      document.addEventListener("keydown", handleEscape);
    }
    if (isOpen && modalConfig?.modalOptions?.preventScroll) {
      document.body.style.overflow = "hidden";
    }
    if (isOpen && modalConfig?.modalOptions?.onOpen) {
      modalConfig.modalOptions.onOpen();
    }
    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = "unset";
    };
  }, [isOpen, modalConfig?.modalOptions, handleClose]);
  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!modalConfig) return;
    if (e.target === e.currentTarget && modalConfig.modalOptions?.closeOnOverlayClick) {
      handleClose();
    }
  };
  if (!isOpen || !modalConfig) return null;
  const {
    width,
    height,
    minHeight
  } = modalConfig.modalOptions?.size || {};
  const animation = modalConfig.modalOptions?.animation;
  const animationStyle = animation?.enterDuration || animation?.leaveDuration ? {
    "--enter-duration": animation.enterDuration ? `${animation.enterDuration}ms` : undefined,
    "--leave-duration": animation.leaveDuration ? `${animation.leaveDuration}ms` : undefined
  } as React.CSSProperties : undefined;
  return createPortal(<div id="modal-root" style={animationStyle} className={`fixed inset-0 z-[9999] bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 animate-fadeInCustom ${modalConfig.modalOptions?.overlayClassName || ""}`} onClick={handleOverlayClick}>
      <div className={twMerge("relative bg-white rounded-lg shadow-xl w-full max-h-[90vh] overflow-y-auto", width, height, minHeight, modalConfig.modalOptions?.className)} onClick={e => e.stopPropagation()}>
        <ModalLayout onClose={handleClose}>{modalConfig.component}</ModalLayout>
      </div>
    </div>, document.body);
};